import React from "react";

const PreInspectionHistory = () => {
  const logs = [
    {
      time: "10:00 PM",
      name: "First Name Last Name",
      unit: "Unit 324",
      photos: 5,
      videos: 1,
      description: "Description",
    },
    // Add more logs as needed
  ];

  return (
    <div className="p-4 bg-gray-100 min-h-screen">
      <div className="max-w-4xl mx-auto bg-white shadow-md rounded-lg overflow-hidden">
        <div className="p-4 border-b border-gray-200">
          <h2 className="text-xl font-semibold">Inspection History</h2>
        </div>
        <div className="p-4">
          {logs.map((log, index) => (
            <div key={index} className="py-2 border-b border-gray-200">
              <div className="flex flex-col md:flex-row justify-between items-start md:items-center">
              <span className="text-gray-800 font-medium">{log.name}</span>
                <span className="text-gray-600">{log.time}</span>
                <span className="text-gray-600">{log.unit}</span>
              </div>
              <div className="flex flex-col md:flex-row justify-between items-start md:items-center mt-2">
                <span className="text-gray-600">
                  {log.photos} Photo{log.photos > 1 ? "s" : ""}
                </span>
                <span className="text-gray-600">
                  {log.videos} Video{log.videos > 1 ? "s" : ""}
                </span>
              </div>
              <div className="mt-2">
                <span className="text-gray-600">{log.description}</span>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default PreInspectionHistory;
