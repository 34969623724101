import React, { useState } from "react";
import { useAuth } from "../../../AuthContext";
import { useNavigate } from "react-router-dom";
import { getAllCompany, createCompany } from "../../../api/CompanyAPI";
import { userCompanyConnection } from "../../../api/userAPI";
import { FaUserTie, FaUser } from "react-icons/fa";
import Button from "../../../components/Button";
import Select from "react-select";

export default function Dashboard() {
  const { user, updateUser, logout } = useAuth();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [userType, setUserType] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [selectedCompany, setSelectedCompany] = useState("");
  const [companies, setCompanies] = useState([
    { _id: 1, name: "Company A" },
    { _id: 2, name: "Company B" },
    { _id: 3, name: "Company C" },
  ]);
  // Transform the companies array to the format expected by react-select
  const companyOptions = companies.map((company) => ({
    label: company.name,
    value: company._id,
  }));

  if (!user) {
    return <p>Loading...</p>;
  }

  const handleLogout = () => {
    logout();
    navigate("/login");
  };

  const newCompany = async () => {
    setLoading(true);
    setError(null);

    try {
      await createCompany(companyName);
      setCompanyName("");

      updateUser({ ...user, isNewUser: false });
      navigate("/dashboard");
    } catch (error) {
      console.error("Failed to create company", error);
      setError("Failed to create the company. Please try again.");
      setLoading(false);
    }
  };

  const loadActiveCompanies = async () => {
    try {
      const allCompanies = await getAllCompany();
      setCompanies(allCompanies);
    } catch (error) {
      console.error("Failed to loadActiveCompanies", error);
    }
  };

  const handleSelect = (type) => {
    setUserType(type);

    if (type === "driver") {
      loadActiveCompanies();
    }
  };

  const handleApplyToCompany = async () => {
    setLoading(true);
    setError(null);

    try {
      await userCompanyConnection(user._id, selectedCompany.value);

      updateUser({ ...user, isNewUser: false });
      navigate("/dashboard");
    } catch (error) {
      console.error("Failed to apply to company", error);
      setError("Failed to apply to company. Please try again.");
      setLoading(false);
    }
  };

  return (
    <div className="flex">
      <div className="flex-1">
        <div className="p-4">
          <div className="flex justify-center items-center h-screen">
            {!userType && (
              <div className="flex justify-between space-x-4">
                <div
                  className="flex flex-col justify-center items-center p-4 border-2 border-solid rounded cursor-pointer hover:bg-gray-100 w-48 h-48"
                  onClick={() => handleSelect("owner")}
                >
                  <FaUserTie className="text-4xl mb-2" />
                  <span>Company Owner</span>
                </div>
                <div
                  className="flex flex-col justify-center items-center p-4 border-2 border-solid rounded cursor-pointer hover:bg-gray-100 w-48 h-48"
                  onClick={() => handleSelect("driver")}
                >
                  <FaUser className="text-4xl mb-2" />
                  <span>Driver</span>
                </div>
              </div>
            )}
            {userType && (
              <div className="w-full max-w-lg mx-auto">
                {userType === "owner" && (
                  <div className="mb-4">
                    <label className="block mb-2">Company Name:</label>
                    <input
                      type="text"
                      value={companyName}
                      onChange={(e) => setCompanyName(e.target.value)}
                      className="border p-2 rounded w-full"
                    />
                    <Button
                      className={
                        companyName.length < 3
                          ? "bg-gray-500 text-white px-4 py-2 rounded mt-2"
                          : "bg-green-500 text-white px-4 py-2 rounded mt-2"
                      }
                      disabled={companyName.length < 3}
                      onClick={newCompany}
                    >
                      Create Company
                    </Button>
                    {error && <div className="text-red-500 mt-2">{error}</div>}{" "}
                    {/* Show error message */}
                  </div>
                )}

                {userType === "driver" && (
                  <div>
                    <label className="block mb-2">Select Company:</label>
                    <Select
                      name="drivers"
                      options={companyOptions}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      value={selectedCompany}
                      isDisabled={loading} // Disable Select when loading
                      onChange={(selectedOptions) =>
                        setSelectedCompany(selectedOptions)
                      }
                    />
                    {selectedCompany && (
                      <Button
                        className="bg-green-500 text-white px-4 py-2 rounded mt-2"
                        onClick={handleApplyToCompany}
                        disabled={loading}
                      >
                        Apply to Company
                      </Button>
                    )}
                    {error && <div className="text-red-500 mt-2">{error}</div>}{" "}
                    {/* Show error message */}
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
