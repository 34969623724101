import axiosInstance from "./axiosInstance";

const getVideoDetails = async (videoId) => {
  try {
    const response = await axiosInstance.get(
      `/inspection/${videoId}/videoDetails`
    );

    if (response.data.status === "fail") {
      throw new Error("Error in getVideoDetails");
    }

    return response.data.body;
  } catch (error) {
    console.error("Failed to fetch units", error);
    throw error;
  }
};

export { getVideoDetails };
