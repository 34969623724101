import AuthLayout from "../../components/AuthLayout";
import { Button } from "../../components/Button";
import { TextField } from "../../components/Fields";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import React, { useState } from "react";
import { useAuth } from "../../AuthContext";

const apiUrl = process.env.REACT_APP_API_URL;

export default function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const { login } = useAuth();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${apiUrl}/auth/login`, {
        email,
        password,
      });
      login(response.data); // Pass the entire response data

      if (response.data.user.isNewUser) {
        navigate("/dashboard/new-user"); // in this page I am decide who is the user
        return;
      } else {
        navigate("/dashboard/units"); // in this page I know who is the user
      }
    } catch (error) {
      setError("Invalid email or password");
      console.error("Login failed", error);
    }
  };

  return (
    <AuthLayout
      title="Sign in to account"
      subtitle={
        <>
          Don’t have an account?{" "}
          <Link to="/register" className="text-cyan-600">
            Sign up
          </Link>{" "}
          for a free trial.
        </>
      }
    >
      <form onSubmit={handleSubmit}>
        <div className="space-y-6">
          <TextField
            label="Email address"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <TextField
            label="Password"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
          {error && <p className="text-red-500">{error}</p>}
          <Button type="submit">Sign in</Button>
        </div>
      </form>
    </AuthLayout>
  );
}
