import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import {
  FaTimes,
  FaChevronLeft,
  FaChevronRight,
  FaSpinner,
  FaPlayCircle,
  FaImage,
} from "react-icons/fa";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";

Modal.setAppElement("#root"); // Set the app element for accessibility

const MediaModal = ({
  isOpen,
  media,
  currentIndex,
  onClose,
  onPrev,
  onNext,
  loading,
}) => {
  const [hasImages, setHasImages] = useState(false);

  useEffect(() => {
    const items = media.map((item) => ({
      original: item.src,
      thumbnail: item.src,
      description: item.fileName,
      type: item.type,
      renderItem: () =>
        item.type === "image" ? (
          <img
            src={item.src}
            alt={item.fileName}
            className="w-full h-96 object-contain"
          />
        ) : (
          <video
            src={item.src}
            className="w-full h-80 object-contain"
            controls
          />
        ),
    }));

    setHasImages(items.some((item) => item.type === "image"));
  }, [media]);

  if (!isOpen) return null;

  const currentMedia = media[currentIndex];

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      className="relative bg-white p-4 rounded-lg shadow-lg max-w-full max-h-full h-3/4 w-3/4"
      overlayClassName="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50"
    >
      <button
        className="absolute top-2 right-2 text-white bg-gray-800 bg-opacity-50 p-2 rounded-full z-50"
        onClick={onClose}
      >
        <FaTimes size={24} />
      </button>
      {loading ? (
        <div className="flex flex-col items-center justify-center h-full">
          <FaSpinner className="animate-spin text-gray-600 mr-2" />
          <span>Loading... {currentMedia.fileName}</span>
        </div>
      ) : (
        <div className="flex flex-col items-center justify-center">
          <div className="relative max-w-full max-h-full h-full w-full">
            <ImageGallery
              infinite={true}
              lazyLoad={true}
              showNav={true}
              items={media.map((item) => ({
                original: item.src,
                thumbnail: item.src,
                description: item.fileName,
                type: item.type,
                renderItem: () =>
                  item.type === "image" ? (
                    <img
                      src={item.src}
                      alt={item.fileName}
                      className="w-full h-96 object-contain"
                    />
                  ) : (
                    <video
                      src={item.src}
                      controls
                      className="w-full h-80 object-contain"
                    />
                  ),
              }))}
              startIndex={currentIndex}
              showThumbnails={hasImages}
              showFullscreenButton={true}
              showPlayButton={false}
              renderLeftNav={(onClick, disabled) => (
                <button
                  className="absolute left-0 top-1/2 transform -translate-y-1/2 text-white bg-gray-800 bg-opacity-50 p-2 rounded-full z-50 flex items-center"
                  onClick={onClick}
                  disabled={disabled}
                >
                  <FaImage size={24} className="mr-2" />
                  <FaChevronRight size={24} />
                </button>
              )}
              renderRightNav={(onClick, disabled) => (
                <button
                  className="absolute right-0 top-1/2 transform -translate-y-1/2 text-white bg-gray-800 bg-opacity-50 p-2 rounded-full z-50 flex items-center"
                  onClick={onClick}
                  disabled={disabled}
                >
                  <FaImage size={24} className="mr-2" />
                  <FaChevronRight size={24} />
                </button>
              )}
              renderThumbInner={(item) =>
                item.type === "video" ? (
                  <div className="video-thumbnail flex items-center justify-center bg-gray-800 text-white">
                    <FaPlayCircle size={24} />
                  </div>
                ) : (
                  <img src={item.thumbnail} alt={item.originalAlt} />
                )
              }
            />
          </div>
        </div>
      )}
    </Modal>
  );
};

export default MediaModal;
