import axiosInstance from "./axiosInstance";

const getPreInspections = async (company_id, unit_id) => {
  try {
    const response = await axiosInstance.get(
      `/pre-inspection/${unit_id}/all?company_id=${company_id}`
    );
    return response.data;
  } catch (error) {
    console.error("Failed to create company API", error);
    throw error;
  }
};

const createPreInspection = async (inspectionData) => {
  try {
    const response = await axiosInstance.post(
      `/pre-inspection`,
      inspectionData
    );
    return response.data;
  } catch (error) {
    console.error("Failed to set inspection step", error);
    throw error;
  }
};

const deletePreInspection = async (inspectionData) => {
  try {
    const response = await axiosInstance.post(
      `/pre-inspection/delete`,
      inspectionData
    );
    return response.data;
  } catch (error) {
    console.error("Failed to delete inspection", error);
    throw error;
  }
};

const updatePreInspection = async (inspectionData) => {
  try {
    const response = await axiosInstance.put(
      `/pre-inspection/${inspectionData.id}`,
      inspectionData
    );
    return response.data;
  } catch (error) {
    console.error("Failed to update inspection", error);
    throw error;
  }
};

export {
  getPreInspections,
  createPreInspection,
  deletePreInspection,
  updatePreInspection,
};
