import React, { useState } from "react";
import { useAuth } from "./../../../../AuthContext";
import {
  createPreInspection,
  updatePreInspection,
  deletePreInspection,
} from "./../../../../api/preInspectionAPI";
import { useNavigate, useParams } from "react-router-dom";
import { Button } from "./../../../../components/Button";
import Pictures from "./subComponents/Pictures";
import Videos from "./subComponents/Videos";
import InspectionDetails from "./subComponents/InspectionDetails";
import PrevInspections from "./subComponents/PrevInspections";

const PreInspections = () => {
  const { user } = useAuth();
  const { unitId } = useParams();
  const navigate = useNavigate();

  const [preInspectionId, setPreInspectionId] = useState("");
  const [currentStep, setCurrentStep] = useState(0);
  const [newPreInspection, setNewPreInspection] = useState({
    photos: [],
    videos: [],
    checklist: {},
    description: "",
    uploadedPhotos: [],
    uploadedVideos: [],
  });

  const handleStartNewPreInspection = async () => {
    setCurrentStep(1);

    // Create a new inspection in the backend
    const createNewPreInspection = await createPreInspection({
      type: "pre-inspection",
      company_id: user.currentCompanyRole.company._id,
      user_id: user._id,
      unit_id: unitId,
    });

    if (createNewPreInspection.status === "fail") {
      console.log(
        "Failed to create new pre-inspection",
        createNewPreInspection.body
      );

      setNewPreInspection((prev) => ({
        ...prev,
        uploadedPhotos: createNewPreInspection.body.picture_ids,
        uploadedVideos: createNewPreInspection.body.video_ids,
        description: createNewPreInspection.body.description,
      }));

      setCurrentStep(createNewPreInspection.body.currentStep);
    }

    setPreInspectionId(createNewPreInspection.body._id);
  };

  const handleCancelPreInspection = () => {
    const confirmCancel = window.confirm(
      "All data you have input for the current pre-inspection will be deleted. Do you want to proceed?"
    );
    if (confirmCancel) {
      deletePreInspection({
        type: "pre-inspection",
        currentStep,
        company_id: user.currentCompanyRole.company._id,
        user_id: user._id,
        unit_id: unitId,
        inspectionId: preInspectionId,
      });
      setCurrentStep(0);
      setNewPreInspection({
        photos: [],
        videos: [],
        checklist: {},
        comments: "",
        description: "",
        uploadedPhotos: [],
        uploadedVideos: [],
      });
    }
  };

  const handleDescriptionChange = (description) => {
    setNewPreInspection((prev) => ({ ...prev, description }));
  };

  const handleNextStep = () => {
    if (currentStep === 2) {
      // Save the inspection to the backend
      // Reset the inspection process
      updatePreInspection({
        id: preInspectionId,
        description: newPreInspection.description,
      });
    }
    setCurrentStep(currentStep + 1);
    updatePreInspection({ id: preInspectionId, currentStep: currentStep + 1 });
  };

  const handlePreviousStep = () => {
    if (currentStep === 2) {
      updatePreInspection({
        id: preInspectionId,
        description: newPreInspection.description,
      });
    }
    setCurrentStep(currentStep - 1);
    updatePreInspection({ id: preInspectionId, currentStep: currentStep - 1 });
  };

  const handleFinishInspection = () => {
    // Save the inspection to the backend
    setCurrentStep(currentStep + 1);
    updatePreInspection({
      id: preInspectionId,
      currentStep: currentStep + 1,
      description: newPreInspection.description,
    });

    // Reset the inspection process
    setCurrentStep(0);
    setNewPreInspection({
      photos: [],
      videos: [],
      checklist: {},
      description: "",
      uploadedPhotos: [],
      uploadedVideos: [],
    });

    setPreInspectionId("");

    // Redirect to the units page
    navigate("/dashboard/units");
  };

  const newPhotoAdded = (response) => {
    const photoIds = response.uploadResults.map((photo) => photo._id);
    setNewPreInspection((prev) => ({
      ...prev,
      uploadedPhotos: Array.isArray(photoIds)
        ? [...prev.uploadedPhotos, ...photoIds]
        : [...prev.uploadedPhotos, photoIds],
    }));
  };

  const newVideoAdded = (response) => {
    const videoIds = response.uploadResults.map((video) => video._id);
    setNewPreInspection((prev) => ({
      ...prev,
      uploadedVideos: Array.isArray(videoIds)
        ? [...prev.uploadedVideos, ...videoIds]
        : [...prev.uploadedVideos, videoIds],
    }));
  };

  const renderStepContent = () => {
    switch (currentStep) {
      case 1:
        return (
          <div>
            <h2 className="text-xl font-bold mb-4">
              Step 1: Upload Photos/Videos
            </h2>
            {/* Upload component for photos and videos */}
            <Pictures
              companyId={user.currentCompanyRole.company._id}
              inspectionId={preInspectionId}
              onNewPhotoAdded={newPhotoAdded}
            />

            <Videos
              companyId={user.currentCompanyRole.company._id}
              inspectionId={preInspectionId}
              onNewVideoAdded={newVideoAdded}
            />

            {newPreInspection.uploadedPhotos.length > 0 && (
              <span className="text-green-500 font-semibold center flex justify-center">
                {newPreInspection.uploadedPhotos.length} Photos Uploaded On The
                Cloud
              </span>
            )}

            {newPreInspection.uploadedVideos.length > 0 && (
              <span className="text-green-500 font-semibold center flex justify-center">
                {newPreInspection.uploadedVideos.length} Videos Uploaded On The
                Cloud
              </span>
            )}

            <div className="flex justify-between">
              <div></div> {/* Placeholder for alignment */}
              <Button onClick={handleNextStep}>Next</Button>
            </div>
          </div>
        );
      case 2:
        return (
          <div>
            <h2 className="text-xl font-bold mb-4">Step 2: Checklist</h2>

            {/* Checklist form */}
            <InspectionDetails
              description={newPreInspection.description}
              onDescriptionChange={handleDescriptionChange}
            />

            <div className="flex justify-between">
              <Button onClick={handlePreviousStep}>Previous</Button>
              <Button onClick={handleFinishInspection}>Finish</Button>
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className="space-y-4">
      <div className="flex space-x-4">
        <Button
          onClick={handleStartNewPreInspection}
          className="bg-blue-500 text-white px-4 py-2 rounded"
        >
          Start / Continue Pre-Inspection
        </Button>
        {currentStep > 0 && (
          <Button
            onClick={handleCancelPreInspection}
            className="bg-red-500 text-white px-4 py-2 rounded"
          >
            Cancel Pre-Inspection
          </Button>
        )}
      </div>
      {currentStep > 0 && (
        <div className="bg-white p-4 rounded shadow-md">
          {renderStepContent()}
        </div>
      )}
      <div>
        <h2 className="text-xl font-bold mb-4">Previous Pre-Inspections</h2>

        <PrevInspections />
      </div>
    </div>
  );
};

export default PreInspections;
