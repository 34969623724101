import React, { useEffect } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import NotFound from "./pages/not-found.tsx";
import Home from "./pages/Home/Home.jsx";
import Login from "./pages/auth/Login.jsx";
import Register from "./pages/auth/Register.jsx";
import Dashboard from "./pages/Dashboard/Dashboard.jsx";
import NewUser from "./pages/Dashboard/NewUser/NewUser.jsx";
import { AuthProvider, useAuth } from "./AuthContext.jsx";
import PrivateRoute from "./PrivateRoute.js";
import RedirectAuthenticated from "./RedirectAuthenticated.js";
import { Navigate } from "react-router-dom";
import Units from "./pages/Dashboard/Units/Units.jsx";
import Unit from "./pages/Dashboard/Units/Unit.jsx";
import Drivers from "./pages/Dashboard/Drivers/Drivers.jsx";
import Inspections from "./pages/Dashboard/Units/Inspections/Inspections.jsx";
import { disableReactDevTools } from "@fvilers/disable-react-devtools";

if (process.env.NODE_ENV === "production") disableReactDevTools();

// import moment from "moment-timezone";
// Set the default timezone
// moment.tz.setDefault("America/Chicago");

// Define the routes
const router = createBrowserRouter([
  {
    path: "/",
    element: <Navigate to="/login" />, //<Home />
    errorElement: <NotFound />,
  },
  {
    path: "/login",
    element: <RedirectAuthenticated element={<Login />} />,
    errorElement: <NotFound />,
  },
  {
    path: "/register",
    element: <RedirectAuthenticated element={<Register />} />,
    errorElement: <NotFound />,
  },

  // Private routes
  {
    path: "/dashboard",
    element: <PrivateRoute element={<Dashboard />} />,
    errorElement: <NotFound />,
  },

  {
    path: "/dashboard/new-user",
    element: <PrivateRoute element={<NewUser />} />,
    errorElement: <NotFound />,
  },

  {
    path: "/dashboard/units",
    element: <PrivateRoute element={<Units />} />,
    errorElement: <NotFound />,
  },

  {
    path: "/dashboard/unit/:unitId",
    element: <PrivateRoute element={<Unit />} />,
    errorElement: <NotFound />,
  },

  {
    path: "/dashboard/unit/:unitId/inspections",
    element: <PrivateRoute element={<Inspections />} />,
    errorElement: <NotFound />,
  },

  {
    path: "/dashboard/unit/:unitId/pre-inspections",
    element: <PrivateRoute element={<Inspections />} />,
    errorElement: <NotFound />,
  },

  {
    path: "/dashboard/drivers",
    element: <PrivateRoute element={<Drivers />} />,
    errorElement: <NotFound />,
  },
]);

// Define the Root component
const Root = () => {
  useEffect(() => {
    // Add classes to <html>, <body>, and #root elements
    document.documentElement.classList.add(
      "h-full",
      "bg-gray-50",
      "antialiased"
    );

    // document.body.classList.add("flex", "h-full", "flex-col");
    // const rootElement = document.getElementById("root");
    // if (rootElement) {
    //   rootElement.classList.add("flex", "min-h-full", "flex-col"); // Example #root classes
    // }

    // Cleanup class when component unmounts
    return () => {
      document.documentElement.classList.remove(
        "h-full",
        "bg-gray-50",
        "antialiased"
      );

      // document.documentElement.classList.remove("flex", "h-full", "flex-col");
      // if (rootElement) {
      //   rootElement.classList.remove("flex", "min-h-full", "flex-col");
      // }
    };
  }, []);

  return (
    <AuthProvider>
      <RouterProvider router={router} />
    </AuthProvider>
  );
};

// Create the root element and render the Root component
const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <Root />
  </React.StrictMode>
);
