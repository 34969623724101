import React, { useState } from "react";

const InspectionDetails = ({ description, onDescriptionChange }) => {
  return (
    <div className="mb-4">
      <h2 className="text-xl font-bold mb-2">Pre-Inspection Details</h2>
      <textarea
        className="w-full p-2 border rounded"
        value={description}
        onChange={(e) => onDescriptionChange(e.target.value)}
        placeholder="Write description or comments here..."
        rows="4"
      />
    </div>
  );
};

export default InspectionDetails;
