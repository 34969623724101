import axiosInstance from "./axiosInstance";

const getUnits = async (companyId) => {
  try {
    const response = await axiosInstance.get(`/units/${companyId}`);

    return response.data;
  } catch (error) {
    console.error("Failed to fetch units", error);
    throw error;
  }
};

const createUnit = async (unit) => {
  try {
    const response = await axiosInstance.post("/units", unit);
    return response.data;
  } catch (error) {
    console.error("Failed to create company API", error);
    throw error;
  }
};

const updateUnit = async (unit) => {
  try {
    const response = await axiosInstance.put(`/units/${unit._id}`, unit);
    return response.data;
  } catch (error) {
    console.error("Failed to create company API", error);
    throw error;
  }
};

const deleteUnit = async ({ unit_id, company_Id }) => {
  try {
    const response = await axiosInstance.delete(
      `/units/${unit_id}?company_id=${company_Id}`
    );
    return response.data;
  } catch (error) {
    console.error("Failed to create company API", error);
    throw error;
  }
};

const getUnitPreInspections = async ({ unitId, companyId }) => {
  try {
    const response = await axiosInstance.get(
      `/units/${unitId}/pre-inspections?company_id=${companyId}`
    );
    return response.data;
  } catch (error) {
    console.error("Failed to create company API", error);
    throw error;
  }
};

export { getUnits, createUnit, updateUnit, deleteUnit, getUnitPreInspections };
